import './YandexMap.scss';
import { LitElement, html } from 'lit';
import loadScript from '../../../modules/load-script';
import { getOffsetTop, isElementInViewport } from '../../utils/dom';
import gsap from 'gsap';

type Placemark = {
    id: number;
    coords: [number, number];
    name: string;
    timeWeekdays: string;
    timeWeekends: string;
    avgСheck: string;
    tel: string;
    filters?: string;
    object?: string;
    prop_1?: string;
    prop_1_1?: string;
    prop_1_2?: string;
    prop_1_3?: string;

    prop_2?: string;
    prop_2_1?: string;
    prop_2_2?: string;
    prop_2_3?: string;

    prop_3?: string;
    prop_3_1?: string;
    prop_3_2?: string;
    prop_3_3?: string;

    prop_4?: string;
    prop_4_1?: string;
    prop_4_2?: string;
    prop_4_3?: string;

    prop_5?: string;
    prop_5_1?: string;
    prop_5_2?: string;
    prop_5_3?: string;
};

export interface YandexMap {
    apiKey: string;
    centerLat: number;
    centerLng: number;
    instance: any;
    marker: string;
    zoom: number;
    isCardVisible: boolean;
    currentCoords: [number, number];
    marks: any[];
    activeTabs: number[];
    clusterer: any;
    activeMark: any;
    data?: {
        placemarks: Placemark[];
    };
    _animatedLayout: any;
    _isFetching: boolean;
    _error: Error | null;
    _observer: IntersectionObserver;
}

const header = document.querySelector<HTMLElement>('.js-header');

/* Анимация появления карточки */

export class YandexMap extends LitElement {
    constructor() {
        super();

        this._closeCard = this._closeCard.bind(this);

        this.instance = null;
        this.zoom = 14.1;
        this._isFetching = false;
        this.currentCoords = [0, 0];
        this.data = this.getAttribute('map-data') ? JSON.parse(this.getAttribute('map-data')!) : null;
        this.marks = [];
        this.activeTabs = [];
        this.clusterer;
        this.isCardVisible = false;

        this._observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    obs.unobserve(entry.target);
                    this._init();
                }
            });
        });
    }

    static get properties() {
        return {
            centerLat: {
                type: Number,
                attribute: 'center-lat',
                reflect: true,
            },
            centerLng: {
                type: Number,
                attribute: 'center-lng',
                reflect: true,
            },
            apiKey: {
                type: String,
                attribute: 'api-key',
            },
            zoom: {
                type: Number,
                reflect: true,
            },
            marker: {
                type: String,
                reflect: true,
            },
            data: {
                type: Object,
            },
            _isFetching: {
                type: Boolean,
                attribute: false,
            },
            _error: {
                attribute: false,
            },
            isCardVisible: {
                type: Boolean,
            },
        };
    }

    createRenderRoot() {
        return this;
    }

    _init() {
        this._error = null;
        this._isFetching = true;
        loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${this.apiKey}&lang=ru_RU`)
            .then(() => {
                const { ymaps } = window;
                ymaps.ready(() => {
                    const _this = this;
                    this.instance = new ymaps.Map(this.renderRoot.querySelector('.map'), {
                        center: [this.centerLat, this.centerLng],
                        zoom: this.zoom,
                        controls: ['zoomControl', 'routeButtonControl', 'geolocationControl'],
                    });

                    const centerButton = new ymaps.control.Button({
                        data: {
                            content: 'Шерегеш',
                            center: [this.centerLat, this.centerLng],
                            zoom: this.zoom,
                        },
                    });
                    this.instance.controls.add(centerButton, { float: 'left' });

                    centerButton.events.add('click', (e: any) => {
                        const item = e.get('target');

                        this.instance.setCenter(item.data.get('center'), item.data.get('zoom'));
                    });

                    const elements: any[] = [];

                    const animatedLayout = ymaps.templateLayoutFactory.createClass(
                        `<button class="map-pin__box map-placemark js-map-marker">
                        <div class="map-pin">
                        <span class="map-pin__close-icon"></span>
                        <span class="map-pin__text"></span>
                        </button>
                        `,
                        {
                            build() {
                                animatedLayout.superclass.build.call(this);

                                const element = this.getParentElement().getElementsByClassName('map-placemark')[0];
                                elements.push(element);
                                // Если метка выбрана, то увеличим её размер.
                                const size = 48;
                                // Зададим фигуру активной области.
                                this.getData().options.set('shape', {
                                    type: 'Circle',
                                    coordinates: [size / 2, size / 2],
                                    radius: size / 2,
                                });
                                // Если метка выбрана, то зададим класс и запустим анимацию.
                                if (this.isActive) {
                                    element.classList.add('is-active');
                                } else if (this.inited) {
                                    element.classList.remove('is-active');
                                }

                                const closeAll = () => {
                                    _this.isCardVisible = false;
                                    if (_this.activeMark && _this.activeMark !== this) {
                                        _this.activeMark.isActive = false;
                                    }
                                    _this.renderRoot.querySelector('.card.is-active')?.classList.remove('is-active');
                                    elements.forEach((el) => {
                                        el.classList.remove('is-active');
                                    });
                                };

                                _this.addEventListener('overlay-click', closeAll);

                                if (!this.inited) {
                                    this.inited = true;
                                    this.isActive = false;
                                    // При клике по метке будем перестраивать макет.
                                    this.getData().geoObject.events.add(
                                        'click',
                                        function (this: any, event: any) {
                                            closeAll();

                                            this.isActive = !this.isActive;
                                            _this.isCardVisible = this.isActive;
                                            const mark = event.get('target');
                                            const index = _this.marks.indexOf(mark);

                                            if (this.isActive) {
                                                element.classList.add('is-active');
                                                if (index > -1) {
                                                    _this.renderRoot
                                                        .querySelectorAll('.card')
                                                        [index]?.classList.add('is-active');

                                                    if (!isElementInViewport(_this)) {
                                                        window.scrollTo({
                                                            top: getOffsetTop(_this) - (header?.offsetHeight || 0),
                                                            behavior: 'smooth',
                                                        });
                                                    }
                                                }
                                            }

                                            _this.activeMark = this;
                                        },
                                        this,
                                    );
                                    this.getData().geoObject.events.add(
                                        'mouseenter',
                                        function (this: any) {
                                            element.classList.add('is-hovered');
                                        },
                                        this,
                                    );
                                    this.getData().geoObject.events.add(
                                        'mouseleave',
                                        function (this: any) {
                                            element.classList.remove('is-hovered');
                                        },
                                        this,
                                    );
                                }
                            },
                        },
                    );
                    this._animatedLayout = animatedLayout;

                    if (this.data) {
                        if (this.data.placemarks?.[0]?.coords) {
                            this.currentCoords = this.data.placemarks[0].coords;
                        }

                        this.data.placemarks.forEach((placemark) => {
                            this._addMarker(...placemark.coords);
                        });

                        this.dispatchEvent(new Event('init'));

                        this.clusterer = new ymaps.Clusterer({
                            clusterIconLayout: ymaps.templateLayoutFactory.createClass(
                                '<div class="cluster-icon"> <span class="cluster-text">{{ properties.geoObjects.length }}</span></div>',
                            ),
                            // Чтобы метка была кликабельной, переопределим ее активную область.
                            clusterIconShape: {
                                type: 'Rectangle',
                                coordinates: [
                                    [0, 0],
                                    [60, 60],
                                ],
                            },
                            hasBalloon: false,
                            // gridSize: 256
                        });

                        this.clusterer.add(this.marks);
                        this.instance.geoObjects.add(this.clusterer);
                    }

                    // Фильтрация маркеров
                    const container = document.querySelector<HTMLElement>('.js-map-container');
                    container?.addEventListener('setFilters', () => {
                        const filters = container.querySelectorAll<HTMLInputElement>('[data-map-filter]');

                        // смотрим какие чекбоксы выбраны
                        let activeCheckboxes: any[] = [];
                        filters.forEach((filter) => {
                            if (filter.checked) {
                                activeCheckboxes.push(filter.dataset.mapFilter);
                            }
                        });

                        // Если выбран фильтр "Все линейки"
                        if (activeCheckboxes && activeCheckboxes[0] == 'all') {
                            this.data?.placemarks.forEach((placemark, i) => {
                                this.marks[i].options.set('visible', true);
                                this.clusterer.add(this.marks[i]);
                            });

                            return;
                        }

                        // если выбраны другие фильтры
                        this.data?.placemarks.forEach((placemark, i) => {
                            const valuesPlacemark = placemark.filters;
                            const valuesPlacemarkArr = valuesPlacemark?.split(', ');
                            let countCoincidence = 0;

                            // проверяем совпадения маркеров с фильтрами
                            if (valuesPlacemarkArr) {
                                for (let i = 0; i < valuesPlacemarkArr.length; i++) {
                                    for (let j = 0; j < activeCheckboxes.length; j++) {
                                        if (activeCheckboxes[j] == valuesPlacemarkArr[i]) {
                                            countCoincidence++;
                                        }
                                    }
                                }
                            }

                            if (countCoincidence == 0) {
                                // если маркер не совпадает с фильтрами
                                this.marks[i].options.set('visible', false);
                                this.clusterer.remove(this.marks[i]);
                            } else {
                                //  если маркер совпадает с фильтрами
                                this.marks[i].options.set('visible', true);
                                this.clusterer.add(this.marks[i]);
                            }
                        });
                    });

                    this.instance.behaviors.disable('scrollZoom');

                    if (matchMedia('(pointer: coarse)').matches) {
                        //  this.instance.behaviors.disable('drag');
                    }

                    this.instance.events.add('click', this._closeCard);

                    window.addEventListener('resize', this._onResize);
                });
            })
            .catch((err) => {
                this._error = err;
                throw err;
            })
            .finally(() => {
                this._isFetching = false;
            });
    }

    connectedCallback() {
        super.connectedCallback();

        if (!this.apiKey) {
            throw new Error('API key not provided.');
        }

        this._observer.observe(this);
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._observer.disconnect();
        window.removeEventListener('resize', this._onResize);
        this._error = null;

        if (this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
    }

    protected _onResize() {
        this.instance?.container.fitToViewport();
    }

    setActiveMarkById(id: number) {
        const placemark = this.data?.placemarks.find((placemark) => placemark.id === id);

        if (placemark) {
            const index = this.data?.placemarks.indexOf(placemark);

            if (typeof index === 'number') {
                const mark = this.marks[index];
                mark?.events.fire('click', {
                    coordPosition: mark.geometry.getCoordinates(),
                    target: mark,
                });
            }
        }
    }

    protected _addMarker(lat: number, lng: number) {
        const markOptions = {
            iconLayout: this._animatedLayout,
            hasBalloon: false,
        };
        const mark = new ymaps.Placemark([lat, lng], {}, markOptions);
        this.marks.push(mark);
        this.instance.geoObjects.add(mark);
    }

    protected _renderMap() {
        if (this._isFetching) {
            return html`<div class="map-loader">Загружаем карту...</div>`;
        }

        if (this._error) {
            return html`<div>${this._error.message}</div>`;
        }

        return '';
    }

    _closeCard() {
        this.dispatchEvent(new Event('overlay-click', { composed: true }));
    }

    render() {
        return html` <div class="map-container">
            <div class="map">${this._renderMap()}</div>
            ${this.data?.placemarks.map(
                (data) => html`
                    <div class="card map-card" data-filter-map-content="${data.filters}">
                        <div class="map-card__inner">
                            <div class="card__top">
                                ${data.name ? html`<div class="h4 map-pin__heading">${data.name}</div>` : ''}
                                <button class="card-close-btn" aria-label="Закрыть" @click="${this._closeCard}">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13 1.00049L1 13.0005M13 13.0005L1 1.00049"
                                            stroke="#303030"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div class="card__bottom">
                                ${data.prop_1 ? html`<p class="text-b map-pin__title">${data.prop_1}</p>` : ''}
                                ${data.prop_1 ? html` <div class="card__address card__item"></div>` : ''}
                                ${data.prop_1_1 ? html`${data.prop_1_1}<br />` : ''}
                                ${data.prop_1_2 ? html`${data.prop_1_2}<br />` : ''}
                                ${data.prop_1_3 ? html`${data.prop_1_3}<br />` : ''} ${data.prop_1 ? html`</div>` : ''}
                                ${data.prop_2 ? html`<p class="text-b map-pin__title">${data.prop_2}</p>` : ''}
                                ${data.prop_2 ? html` <div class="card__address card__item"></div>` : ''}
                                ${data.prop_2_1 ? html`${data.prop_2_1}<br />` : ''}
                                ${data.prop_2_2 ? html`${data.prop_2_2}<br />` : ''}
                                ${data.prop_2_3 ? html`${data.prop_2_3}<br />` : ''} ${data.prop_2 ? html`</div>` : ''}
                                ${data.prop_3 ? html`<p class="text-b map-pin__title">${data.prop_3}</p>` : ''}
                                ${data.prop_3 ? html` <div class="card__address card__item"></div>` : ''}
                                ${data.prop_3_1 ? html`${data.prop_3_1}<br />` : ''}
                                ${data.prop_3_2 ? html`${data.prop_3_2}<br />` : ''}
                                ${data.prop_3_3 ? html`${data.prop_3_3}<br />` : ''} ${data.prop_3 ? html`</div>` : ''}
                                ${data.prop_4 ? html`<p class="text-b map-pin__title">${data.prop_4}</p>` : ''}
                                ${data.prop_4 ? html` <div class="card__address card__item"></div>` : ''}
                                ${data.prop_4_1 ? html`${data.prop_4_1}<br />` : ''}
                                ${data.prop_4_2 ? html`${data.prop_4_2}<br />` : ''}
                                ${data.prop_4_3 ? html`${data.prop_4_3}<br />` : ''} ${data.prop_4 ? html`</div>` : ''}
                                ${data.prop_5 ? html`<p class="text-b map-pin__title">${data.prop_5}</p>` : ''}
                                ${data.prop_5 ? html` <div class="card__address card__item"></div>` : ''}
                                ${data.prop_5_1 ? html`${data.prop_5_1}<br />` : ''}
                                ${data.prop_5_2 ? html`${data.prop_5_2}<br />` : ''}
                                ${data.prop_5_3 ? html`${data.prop_5_3}<br />` : ''} ${data.prop_5 ? html`</div>` : ''}
                            </div>

                            ${data.object
                                ? html` <a href="${data.object}" class="btn button-text map-pin__about-btn"
                                      >Перейти к объекту</a
                                  >`
                                : ''}
                        </div>
                    </div>
                `,
            )}
        </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-yandex-map': YandexMap;
    }
}
