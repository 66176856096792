/* global BUILD_TYPE */
/* eslint global-require: "off" */
// import 'css-paint-polyfill';
import 'focus-visible';

export async function applyPolyfills() {
    if (BUILD_TYPE === 'legacy') {
        require('core-js/es/symbol');
        require('core-js/es/promise/finally');
        require('document-register-element/build/document-register-element');
        require('whatwg-fetch');
    }

    const polyfillsToFetch: Promise<any>[] = [];

    // if (!('IntersectionObserver' in window)) {
    //     polyfillsToFetch.push(import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer'));
    // }

    if (polyfillsToFetch.length > 0) {
        await Promise.all(polyfillsToFetch);
    }
}
