import type { ITransitionPage } from '@barba/core';
import gsap from 'gsap';
import { menuTl } from '../animations/menu-trans';

const header = document.querySelector<HTMLElement>('.a-header');

export default {
    leave({ current }): Promise<void> {
        return new Promise(async (resolve) => {
            // Определяем аниманию перехода между страниц здесь, в конце асинхронного действия вызываем 'resolve'.
            menuTl.reverse(-1);
            const tl = gsap.timeline({
                defaults: {
                    stagger: {
                        from: 0,
                        amount: 0.25,
                    },
                },
                onComplete: () => {
                    if (header) {
                        header.style.transition = 'none';
                        header.classList.add('is-out');
                        setTimeout(() => {
                            header.style.transition = '';
                        }, 20);
                        resolve();
                    }
                },
            });
            tl.to('.js-curtain-part', {
                opacity: 1,
            });
        });
    },
    after(data): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo({ top: 0, behavior: 'auto' });
            gsap;
            const tl = gsap.timeline({
                defaults: {
                    stagger: {
                        from: 0,
                        amount: 0.25,
                    },
                },
                onComplete: () => {
                    resolve();
                },
            });
            tl.to(
                '.js-curtain-part',
                {
                    opacity: 0,
                },
                0,
            ).add(() => {
                header?.classList.remove('is-out');
            }, 0.4);
        });
    },
    once(next): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo({ top: 0, behavior: 'auto' });
            resolve();
        });
    },
} as ITransitionPage;
