import SlimSelect from 'slim-select';
const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    const selectList = container.querySelectorAll<HTMLElement>('.js-dropdown');

    selectList.forEach(select => {
        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select: select,
                showSearch: false,
                placeholder: select.dataset.namePlaceholder,
                showContent: 'down'
            });
            map.set(select, newselect);

            select.classList.add('select--initialization');

            select.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });
        }
    })
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-dropdown')).forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
