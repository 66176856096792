import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const map = new WeakMap();
let timer: NodeJS.Timeout;

function init(container: HTMLElement | Document = document) {
    const parallaxImages = Array.from(container.querySelectorAll('.js-parallax-image'));
    parallaxImages.forEach((image) => {
        const trigger = ScrollTrigger.create({
            trigger: image,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 0.8,
            animation: gsap.to(image, {
                y: '-10%',
            }),
            // markers: true,
        });

        map.set(image, trigger);
    });

    timer = setTimeout(() => {
        ScrollTrigger.refresh();
    }, 100);
}

function destroy(container: HTMLElement | Document = document) {
    const parallaxImages = Array.from(container.querySelectorAll('.js-parallax-image'));
    clearTimeout(timer);
    parallaxImages.forEach((image) => {
        const trigger = map.get(image);
        trigger.kill();
        map.delete(trigger);
    });
}

const _module = { init, destroy };

export default _module;
