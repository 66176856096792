import { getCSSCustomProp } from '../utils/css';
import * as Cookies from 'js-cookie';
import timeout from '../utils/timeout';

export default function initInfoBanner() {
    if (Cookies.get('INFO_BANNER_CLOSED') !== 'true') {
        const infoBanner = document.querySelector<HTMLElement>('.js-info-banner');
        const infoBannerClose = document.querySelector<HTMLElement>('.js-info-banner-close');

        document.body.classList.add('is-info-banner-visible');

        const onClick = async () => {
            Cookies.set('INFO_BANNER_CLOSED', 'true', {
                expires: 1, // days
            });
            window.removeEventListener('resize', onResize);
            document.body.classList.remove('is-info-banner-visible');
            await timeout(600);
            infoBanner?.remove();
        };

        const onResize = () => {
            if (infoBanner) {
                const infoBannerHeight = getCSSCustomProp(infoBanner, 'height');

                document.documentElement.style.setProperty('--banner-height', `${infoBannerHeight}`);
            }
        };

        onResize();

        window.addEventListener('resize', onResize);

        if (infoBannerClose) {
            infoBannerClose.addEventListener('click', onClick, { once: true });
        }
    }
}
