/* eslint-disable max-len */

export default function initSosButton() {
    const sendMessageButton = document.querySelector<HTMLElement>('.js-send-message');

    if (sendMessageButton) {
        const number = sendMessageButton.dataset.target;
        const sendMessage = () => {
            if (number) {
                window.location.assign(
                    `https://wa.me/${number}/?text=%D0%AF%20%D1%85%D0%BE%D1%87%D1%83%20%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B8%D1%82%D1%8C%20%D0%94%D0%B8%D1%80%D0%B5%D0%BA%D1%86%D0%B8%D0%B8`,
                );
            } else {
                alert('К сожалению, аккаунт whatsapp сейчас недоступен, попробуйте позвонить');
            }
        };

        const onClick = () => {
            sendMessage();
        };
        sendMessageButton.addEventListener('click', onClick);
    }
}
