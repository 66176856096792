import dayjs from 'dayjs';
let seasonSwither: HTMLElement | null;
let season: string | undefined;
function getCookie(name: string) {
    let matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setSeason(season: string) {
    document.querySelector('body')?.classList.add(season);
    if (season == 'summer') {
        seasonSwither?.classList.add('summer');
        document.documentElement.style.setProperty('--page-theme', '#19AD48');
    } else {
        seasonSwither?.classList.remove('summer');
        document.documentElement.style.setProperty('--page-theme', '#4FABE9');
    }
}

function init(container: HTMLElement | Document = document) {
    seasonSwither = container.querySelector<HTMLElement>('.season-switcher__box');
    season = getCookie('season');

    if (!season) {
        const date = dayjs().month();
        if (date < 4 || date > 9) {
            document.cookie = `season=winter; max-age=${86400 * 30}`;
            setSeason('winter');
        } else {
            document.cookie = `season=summer; max-age=${86400 * 30}`;
            setSeason('summer');
        }
    } else {
        setSeason(season);
    }
}

const _module = { init };

export default _module;
