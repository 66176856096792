import Swiper, {} from 'swiper';

let sliders: HTMLElement[] = [];
const map = new WeakMap();

const swiperOptions = {
    loop: false,
    slidesPerView:'auto',
    grabCursor: true,
    spaceBetween: 4,
    breakpoints: {
        767: {
            loop: false,
            slidesPerView:'auto',
            grabCursor: true,
            spaceBetween: 6,
        },
        1025: {
            loop: false,
            slidesPerView:'auto',
            grabCursor: true,
            spaceBetween: 8,
        },
    },
};

function sliderInit() {
    if (sliders) {
        sliders.forEach((sliderEl) => {
            const swiper = new Swiper(sliderEl, swiperOptions)
            map.set(sliders, swiper);
        });
    }
}

function init(container: HTMLElement | Document = document) {
    sliders = Array.from(container.querySelectorAll<HTMLElement>('.js-filters-drag'));
    sliderInit();
}

function destroy(container: HTMLElement | Document = document) {
    if (sliders) {
        sliders.forEach((sliderEl) => {
            const sliders = map.get(sliderEl);
            if (sliders) {
                sliders.destroy();
                map.delete(sliderEl);
            }
        });
    }
    sliders = [];
}

const _module = { init, destroy };

export default _module;
