import Swiper, { Navigation, Lazy, Pagination } from 'swiper';
import 'swiper/css';

let sliders: HTMLElement[] = [];
let prevBtn: HTMLElement | null | undefined;
let nextBtn: HTMLElement | null | undefined;
const map = new WeakMap();

function withLeadingZero(num: number, targetLength = 2) {
    return num.toString().padStart(targetLength, '0');
}

function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <div class="slider-control">
            <button class="btn-arrow btn-arrow--prev js-btn-prev-image" aria-label="Предыдущий слайд">
                <svg class="arrow-icon" width="16" height="9" viewBox="0 0 16 9" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4.5L2 4.5M2 4.5L5.44444 8M2 4.5L5.44444 1" stroke="#2F2F2F"
                        stroke-width="2" stroke-linecap="round" />
                </svg>
            </button>
            <button class="btn-arrow btn-arrow--next js-btn-next-image" aria-label="Следующий слайд">
                <svg class="arrow-icon" width="16" height="9" viewBox="0 0 16 9" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.5H14M14 4.5L10.5556 1M14 4.5L10.5556 8" stroke="#2F2F2F"
                        stroke-width="2" stroke-linecap="round" />
                </svg>
            </button>
        </div>
    `;
}

function init(container: HTMLElement | Document = document) {
    sliders = Array.from(container.querySelectorAll<HTMLElement>('.js-image-slider'));
    sliders.forEach((sliderEl) => {
        const slides = sliderEl.querySelectorAll('.swiper-slide');
        const navigation = sliderEl.querySelector<HTMLElement>('.js-slider-navigation');

        if (slides.length > 1) {
            if (navigation) createNavigation(navigation);
            prevBtn = navigation?.querySelector<HTMLElement>('.js-btn-prev-image');
            nextBtn = navigation?.querySelector<HTMLElement>('.js-btn-next-image');

            const imageSlider = new Swiper(sliderEl, {
                modules: [Pagination, Navigation, Lazy],
                slidesPerView: 'auto',
                spaceBetween: 4,
                centeredSlides: true,
                loop: true,
                speed: 800,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                pagination: {
                    el: '.js-slider-counter',
                    type: 'fraction',
                    formatFractionCurrent: withLeadingZero,
                    formatFractionTotal: withLeadingZero,
                },
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                },
                breakpoints: {
                    768: {
                        spaceBetween: 6,
                    },
                    1280: {
                        spaceBetween: 8,
                    },
                },
            });

            map.set(sliderEl, imageSlider);
        } else {
            sliderEl.classList.add('is-alone-slide');
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    if (sliders) {
        sliders.forEach((sliderEl) => {
            const sliders = map.get(sliderEl);
            if (sliders) {
                sliders.destroy();
                map.delete(sliderEl);
            }
        });
    }
    sliders = [];
}

const _module = { init, destroy };

export default _module;
