import axios from 'axios';
import { triggerCustomEvent } from '../utils/trigger-event';
import getCookie from '../utils/get-cookie';
import LazyLoad from 'vanilla-lazyload';
import gsap from 'gsap';
import timeout from '../utils/timeout';

const NO_SCROLL_CLASS = 'no-scroll';

type Instance = {
    loadVideo?: () => Promise<void> | null;
};

const instance: Instance = {};

function init() {
    function detectSeasonAndDeviceType() {
        const introVideoEl = document.querySelector<HTMLVideoElement>('.js-intro-video');

        if (!introVideoEl) {
            return;
        }
        const summerMobileVideos = Array.from(document.querySelectorAll('.js-intro-video--mobile--summer'));
        const summerDesctopVideos = Array.from(document.querySelectorAll('.js-intro-video--desctop--summer'));
        const winterMobileVideos = Array.from(document.querySelectorAll('.js-intro-video--mobile--winter'));
        const winterDesctopVideos = Array.from(document.querySelectorAll('.js-intro-video--desctop--winter'));

        const season = getCookie('season');
        const deviceType = matchMedia('(max-width: 1023px)').matches ? 'mobile' : 'desctop';

        let targetArray;
        let notTargetArray;

        if (season === 'summer') {
            if (deviceType === 'mobile') {
                targetArray = summerMobileVideos;
                notTargetArray = [...winterMobileVideos, ...winterDesctopVideos, ...summerDesctopVideos];
            } else {
                targetArray = summerDesctopVideos;
                notTargetArray = [...winterMobileVideos, ...winterDesctopVideos, ...summerMobileVideos];
            }
        } else {
            if (deviceType === 'mobile') {
                targetArray = winterMobileVideos;
                notTargetArray = [...winterDesctopVideos, ...summerMobileVideos, ...summerDesctopVideos];
            } else {
                targetArray = winterDesctopVideos;
                notTargetArray = [...winterMobileVideos, ...summerMobileVideos, ...summerDesctopVideos];
            }
        }

        targetArray.forEach((video) => {
            const url = video.getAttribute('data-src');
            if (url) {
                video.setAttribute('src', url);
            }
        });

        notTargetArray.forEach((video) => video.removeAttribute('src'));
    }

    function setImagesVisible() {
        const introVideoEl = document.querySelector<HTMLVideoElement>('.js-intro-video');
        introVideoEl?.classList.add('visually-hidden');
        const introPictureWinter = document.querySelector<HTMLElement>('.js-intro-picture-winter');
        const introPictureSummer = document.querySelector<HTMLElement>('.js-intro-picture-summer');
        const season = getCookie('season');

        if (season === 'winter') {
            if (introPictureWinter) {
                introPictureWinter.classList.remove('visually-hidden');
                LazyLoad.load(introPictureWinter, {});
            }
        } else {
            if (introPictureSummer) {
                introPictureSummer.classList.remove('visually-hidden');
                LazyLoad.load(introPictureSummer, {});
            }
        }
    }

    window.addEventListener('resize', detectSeasonAndDeviceType);

    function createPreloader() {
        const preloader = document.querySelector('.js-preloader');
        const indicator = document.querySelector('.js-preloader-text');
        const preloaderLogo = document.querySelector('.js-preloader-logo');
        const preloaderLogoSquare = document.querySelector('.js-preloader-logo-square');
        const preloaderLogoWingLeft = document.querySelector('.js-preloader-logo-wing-left');
        const preloaderLogoWingRight = document.querySelector('.js-preloader-logo-wing-right');
        const preloaderLogoSmallWingLeft = document.querySelector('.js-preloader-logo-wing-small-left');
        const preloaderLogoSmallWingRight = document.querySelector('.js-preloader-logo-wing-small-right');

        let idleTl = gsap.timeline({
            defaults: {
                repeat: -1,
                duration: 2,
                ease: 'back.out(1.1)',
            },
        });

        gsap.to(preloaderLogo, { opacity: 1, duration: 0.4 });
        gsap.set(preloaderLogoSquare, { y: 18.5 });
        idleTl.fromTo(preloaderLogoSquare, { rotate: -45 }, { rotate: 315 });

        const REVEAL_LOGO_DURATION = 0.5;

        const images = Array.from(document.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));

        let assets: number[] = [];
        let wasBodyLocked = false;
        document.body.classList.add(NO_SCROLL_CLASS);

        async function enter() {
            if (preloader) {
                wasBodyLocked = document.body.classList.contains('no-scroll');
                if (!wasBodyLocked) {
                    document.body.classList.remove(NO_SCROLL_CLASS);
                }
            }
        }

        function leave() {
            triggerCustomEvent(document, 'preloader.complete');
            if (preloader) {
                gsap.set(preloaderLogoWingLeft, { skewY: -45 });
                gsap.set(preloaderLogoSmallWingLeft, { skewY: -45 });
                gsap.set(preloaderLogoWingRight, { skewY: 45 });
                gsap.set(preloaderLogoSmallWingRight, { skewY: 45 });

                const tl = gsap.timeline({
                    defaults: { repeat: 0, duration: REVEAL_LOGO_DURATION },
                    onComplete: () => {
                        preloader.classList.add('preloader--complete');
                        setTimeout(() => {
                            preloader.classList.add('preloader--hidden');
                            triggerCustomEvent(document, 'preloader.hidden');
                        }, 800);
                    },
                });

                idleTl.pause();
                const currentRotation = gsap.getProperty(preloaderLogoSquare, 'rotate') as number;
                const countOfFourhyFiveDeg = currentRotation / 90;
                const countOfFullFourthyFiveDeg = Math.floor(countOfFourhyFiveDeg);
                const countOfFloatDeg = countOfFourhyFiveDeg - countOfFullFourthyFiveDeg;
                const isMoreThanHalf = countOfFloatDeg > 0.5;

                tl.to(preloaderLogoSquare, {
                    rotate: isMoreThanHalf ? countOfFullFourthyFiveDeg * 90 + 45 : countOfFullFourthyFiveDeg * 90 - 45,
                    y: 0,
                    overwrite: true,
                })
                    .to(preloaderLogoWingRight, { scaleX: 1 }, `-=${REVEAL_LOGO_DURATION / 2}`)
                    .to(preloaderLogoWingLeft, { scaleX: 1 })
                    .to(preloaderLogoSmallWingRight, { scaleX: 1 }, `-=${REVEAL_LOGO_DURATION / 4}`)
                    .to(preloaderLogoSmallWingLeft, { scaleX: 1 }, `-=${REVEAL_LOGO_DURATION / 2}`);

                if (!wasBodyLocked) {
                    document.body.classList.remove(NO_SCROLL_CLASS);
                }
            }
        }

        async function loadAsset(url: string, index: number, amount: number) {
            try {
                await axios.get(url, {
                    onDownloadProgress: (progressEvent) => {
                        let percent = 0;
                        const _percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        assets[index] = _percentCompleted;

                        for (let i = 0; i < assets.length; i++) {
                            percent += assets[i];
                        }
                    },
                });
            } catch (err) {
                let percent = 0;
                assets[index] = 100;

                for (let i = 0; i < assets.length; i++) {
                    percent += assets[i];
                }
            }
        }

        async function loadVideoPromise() {
            detectSeasonAndDeviceType();

            const promise = new Promise((resolve, reject) => {
                const introVideoEl = document.querySelector<HTMLVideoElement>('.js-intro-video');
                if (introVideoEl) {
                    introVideoEl.load();

                    introVideoEl.addEventListener('canplaythrough', () => {
                        introVideoEl
                            .play()
                            .then(() => {
                                resolve(introVideoEl);
                            })
                            .catch((err) => {
                                if (err.name === 'NotAllowedError') {
                                    setImagesVisible();
                                    resolve(0);
                                }
                                reject(err);
                            });
                    });
                    introVideoEl.addEventListener('error', function (event) {
                        reject(introVideoEl);
                    });
                } else {
                    resolve(0);
                }
            });

            await promise.then(() => {});
        }

        instance.loadVideo = loadVideoPromise;

        async function loadAssetsFromElement(element: Element | Document = document) {
            triggerCustomEvent(document, 'preloader.begin');

            if (images.length) {
                await Promise.all([...images.map((img, i) => loadAsset(img.currentSrc, i, images.length))]);
            }
        }

        return { enter, leave, loadAssetsFromElement, loadVideoPromise } as const;
    }

    const preloader = createPreloader();

    // Initial load
    preloader.loadVideoPromise().then(async () => {
        preloader.loadAssetsFromElement().then(async () => {
            await timeout(1000);
            preloader.leave();
        });
    });
}

export default { init, instance };
