import delegate from 'delegate';
import barba from '@barba/core';

const hoverableSelector = '.js-hover-link';

const lerp = (a: number, b: number, n: number) => (1 - n) * a + n * b;

function Cursor() {
    const target = { x: 0, y: 0 };
    const cursor = { x: 0, y: 0 };
    const speed = 0.45;
    let clientX = 0;
    let clientY = 0;
    const cursorEl = document.querySelector<HTMLElement>('.js-cursor');
    let raf;

    const cursorSizes = { width: 0, height: 0 };

    if (cursorEl) {
        cursorSizes.width = cursorEl.offsetWidth / 2;
        cursorSizes.height = cursorEl.offsetHeight / 2;
    }

    const onResize = () => {
        if (cursorEl) {
            cursorSizes.width = cursorEl.offsetWidth / 2;
            cursorSizes.height = cursorEl.offsetHeight / 2;
        }
    };

    function mouseEnter(event: any) {
        cursorEl?.classList.add('is-show');

        if (event.target.closest('.news-item--img') !== null) {
            cursorEl?.classList.add('colored');
        } else {
            cursorEl?.classList.remove('colored');
        }
    }

    function mouseLeave(event: any) {
        if (!event.target.parentElement.closest(hoverableSelector)) {
            cursorEl?.classList.remove('is-show');
        }
    }

    function getMouseCoords(event: MouseEvent) {
        clientX = event.clientX / window.innerWidth;
        clientY = event.clientY / window.innerHeight;
    }

    const render = () => {
        cursor.x = lerp(cursor.x, target.x, speed);
        cursor.y = lerp(cursor.y, target.y, speed);
        document.documentElement.style.setProperty('--cursor-x', `${cursor.x}`);
        document.documentElement.style.setProperty('--cursor-y', `${cursor.y}`);
        //cancel loop if mouse stops moving
        const delta = Math.sqrt(Math.pow(target.x - cursor.x, 2) + Math.pow(target.y - cursor.y, 2));
        if (delta < 0.001) {
            cancelAnimationFrame(raf);
            raf = null;
            return;
        }
        //or continue looping if mouse is moving
        raf = requestAnimationFrame(render);
    };

    function mouseMove(event: MouseEvent) {
        if (cursorEl) {
            target.x = event.clientX / window.innerWidth;
            target.y = event.clientY / window.innerHeight;
            if (!raf) {
                raf = requestAnimationFrame(render);
            }
            // cursorEl.style.left = `${clientX - cursorEl.offsetWidth / 2}px`;
            // cursorEl.style.top = `${clientY - cursorEl.offsetHeight / 2}px`;
        }
    }

    function initHovers() {
        delegate(document, hoverableSelector, 'mouseenter', mouseEnter, true);
        delegate(document, hoverableSelector, 'mouseleave', mouseLeave, true);
        delegate(document, hoverableSelector, 'mousemove', mouseMove, true);
    }

    function init() {
        // document.addEventListener('mousemove', getMouseCoords, { passive: true });
        initHovers();
        raf = requestAnimationFrame(render);

        window.addEventListener('resize', onResize);

        barba.hooks.afterLeave(() => {
            cursorEl?.classList.remove('is-show');
        });
    }

    function destroy() {
        // document.removeEventListener('mousemove', getMouseCoords);
        window.removeEventListener('resize', onResize);
    }

    return Object.freeze({ init, destroy });
}

export { Cursor };
