export default function generatePageCurtain() {
    let ELEMENT_SIZE = 260;

    if (window.matchMedia('(max-width: 1439px)').matches) {
        ELEMENT_SIZE = 160;
    }

    if (window.matchMedia('(max-width: 1279px)').matches) {
        ELEMENT_SIZE = 90;
    }

    if (window.matchMedia('(max-width: 767px)').matches) {
        ELEMENT_SIZE = 40;
    }

    const ADDITIONAL_LINES = 3;

    const ELEMENTS_COUNT_X = Math.round(window.innerWidth / ELEMENT_SIZE);
    const ELEMENTS_COUNT_Y = Math.round(window.innerHeight / ELEMENT_SIZE) + ADDITIONAL_LINES;
    const totalCount = ELEMENTS_COUNT_X * ELEMENTS_COUNT_Y;

    const curtainEl = document.querySelector<HTMLElement>('.js-page-curtain');

    if (curtainEl) {
        for (let i = 0; i < ELEMENTS_COUNT_Y; i++) {
            const row = document.createElement('div');
            row.classList.add('curtain-row');
            curtainEl.appendChild(row);
        }

        const rows = document.querySelectorAll<HTMLElement>('.curtain-row');

        for (let i = 0; i < totalCount; i++) {
            const div = document.createElement('div');
            div.classList.add('curtain-part');
            div.classList.add('js-curtain-part');
            div.style.width = `${window.innerWidth / ELEMENTS_COUNT_X}px`;
            div.style.height = `${window.innerHeight / (ELEMENTS_COUNT_Y - ADDITIONAL_LINES)}px`;

            if (i % ELEMENTS_COUNT_X < ELEMENTS_COUNT_X / 2) {
                div.style.transform = 'skewY(45deg)';
            } else {
                div.style.transform = 'skewY(-45deg)';
            }

            rows[Math.floor(i / ELEMENTS_COUNT_X) % ELEMENTS_COUNT_Y].appendChild(div);
        }

        rows.forEach((row) => {
            row.style.transform = `translateY(-${window.innerHeight / (ELEMENTS_COUNT_Y - 2)}px)`;
        });
    }
}
