import barba from '@barba/core';
import { lazyload } from '../inits/lazy-load';
import tabs from '../inits/tabs';
import select from '../inits/select';
import priceSortSelect from '../inits/price-sort-select';
import video from '../inits/video';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import reveal from '../animations/reveal';
import filtersDrag from '../inits/filters-drag';
import burger from '../inits/burger';
// import headerChange from '../inits/header-change';
import gsap from 'gsap';
import headerTheme from '../inits/header-theme';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import imageSlider from '../inits/image-slider';
import vhMobileFix from '../components/vh-mobile-fix';
import firstSection from '../inits/first-bg-section';
import getDate from '../inits/getDate';
import parallaxImages from '../inits/parallax-images';

burger.init();

export function initBaseView() {
    barba.hooks.beforeEnter((data) => {
        gsap.registerPlugin(ScrollToPlugin);
        window.scrollTo({ top: 0, behavior: 'auto' });
        vhMobileFix();
        tabs.init();
        select.init();
        video.init();
        reveal.init(data?.next.container);
        filtersDrag.init();
        priceSortSelect.init();
        imageSlider.init();
        getDate.init();
        //  headerChange.init();
        firstSection.init(data?.next.container);
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }
        lazyload.update();
    });

    barba.hooks.beforeLeave((data) => {
        filtersDrag.destroy();
        // burger.destroy();
        imageSlider.destroy();
        //  headerChange.destroy();
        parallaxImages.destroy(data?.current.container);
    });

    barba.hooks.enter((data) => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }
    });

    barba.hooks.afterEnter((data) => {
        headerTheme.init(data?.next.container);
        parallaxImages.init(data?.next?.container);
    });

    barba.hooks.afterLeave((data) => {
        headerTheme.destroy(data?.current.container);
        priceSortSelect.destroy();
        firstSection.destroy(data?.current.container);
        getDate.destroy();
        reveal.destroy(data?.current.container);
    });
}
