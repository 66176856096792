let tabTriggers: HTMLElement[] = [];

function onClick(this: HTMLElement) {
    const container = this.closest('.js-tabs-container');
    const tabTriggers = container?.querySelectorAll<HTMLElement>('[data-tabs-trigger]');
    const tabs = container?.querySelectorAll('[data-tabs-content]');
    const value = this.dataset.tabsTrigger;
    const activeTab = container?.querySelector(`[data-tabs-content="${value}"]`);

    tabs?.forEach((tab) => tab.classList.remove('is-active'));
    activeTab?.classList.add('is-active');

    tabTriggers?.forEach((tab) => tab.classList.remove('is-active'));
    this.classList.add('is-active');
    container?.dispatchEvent(new CustomEvent('tab-change', { detail: { value } }));
}

function init() {
    tabTriggers = Array.from(document.querySelectorAll<HTMLElement>('[data-tabs-trigger]'));
    tabTriggers.forEach((tab) => tab.addEventListener('click', onClick));
}

function destroy() {
    tabTriggers.forEach((tab) => tab.removeEventListener('click', onClick));
}

const _module = { init, destroy };

export default _module;
