import gsap from 'gsap';

export const menuTl = gsap.timeline({ paused: true });

const burgerPopup = document.querySelector<HTMLElement>('.js-burger-popup');

if (burgerPopup) {
    const burgerPopupNavItem = Array.from(burgerPopup.querySelectorAll<HTMLElement>('.js-burger-menu-nav-item'));
    const burgerPopupWidgetItem = Array.from(burgerPopup.querySelectorAll<HTMLElement>('.js-burger-popup-widget-item'));
    const RADIUS = '40px';

    menuTl
        .to(burgerPopup, { borderRadius: `0 0 ${RADIUS} ${RADIUS}` }, 0)
        .from(
            burgerPopup,
            {
                y: '-100%',
                duration: 0.8,
            },
            0,
        )
        .to(burgerPopup, {
            borderRadius: 0,
        })
        .fromTo(
            burgerPopupNavItem,
            {
                y: 20,
                opacity: 0,
            },
            {
                duration: 0.5,
                ease: 'ease-in-out',
                y: 0,
                opacity: 1,
                stagger: 0.1,
            },
            0.6,
        )
        .fromTo(
            burgerPopupWidgetItem,
            {
                y: 10,
                opacity: 0,
            },
            {
                duration: 0.5,
                y: 0,
                opacity: 1,
                stagger: 0.1,
            },
            0.8,
        );
}
