let time: HTMLElement[] = [];
let date: HTMLElement | null;
let date_header: HTMLElement | null;
let year_header: HTMLElement | null;
let timeInterval: NodeJS.Timer;
let d: Date;
let month: string | number;
let day: string | number;
let hour: string | number;
let minute: string | number;
function getDate() {
    d = new Date();
    day = d.getDate();
    month = d.getMonth() + 1;
    let year = d.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    if (date) {
        date.innerHTML = `${day}.${month}.${year}`;
    }

    if (date_header) {
        date_header.innerHTML = `${day}.${month}`;
    }

    if (year_header) {
        year_header.innerHTML = `${year}`;
    }
}

function getTime() {
    let t = new Date();
    hour = t.getUTCHours() + 7;
    minute = t.getUTCMinutes();

    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;

    if (hour >= 24) {
        hour = hour - 24;
    } else {
        hour = hour;
    }

    time.forEach((el) => {
        el.innerHTML = `${hour}<span class="a-blink">:</span>${minute}`;
    });
}

function init(container: HTMLElement | Document = document) {
    time = Array.from(container.querySelectorAll<HTMLElement>('.js-time'));
    date_header = container?.querySelector<HTMLElement>('.js-date-header');
    year_header = container?.querySelector<HTMLElement>('.js-year-header');
    date = container?.querySelector<HTMLElement>('.js-date');

    getTime();
    timeInterval = setInterval(getTime, 60000);
    getDate();
}

function destroy(container: HTMLElement | Document = document) {
    clearInterval(timeInterval);
}

const _module = { init, destroy };

export default _module;
