import barba from '@barba/core';
import { menuTl } from '../animations/menu-trans';

let burger: HTMLElement | null;
let header: HTMLElement | null;
let burgerPopup: HTMLElement | null;

function burgerChange() {
    if (burger) {
        burger.classList.toggle('is-active');
        if (burger.classList.contains('is-active')) {
            header?.classList.toggle('menu-open');
            menuTl.play();
        } else {
            menuTl.reverse().then(() => header?.classList.toggle('menu-open'));
        }
    }
    // header?.classList.toggle('menu-open');
    document.body.classList.toggle('no-scroll');
}

function closeMenu() {
    burger?.classList.remove('is-active');
    header?.classList.remove('menu-open');
    document.body.classList.remove('no-scroll');
}

function init(container: HTMLElement | Document = document) {
    burger = container.querySelector<HTMLElement>('.burger-btn');
    header = container.querySelector<HTMLElement>('.header');
    burgerPopup = container.querySelector<HTMLElement>('.burger-popup');
    burger?.addEventListener('click', burgerChange);

    barba.hooks.afterEnter(() => {
        closeMenu();
    });
}

function destroy(container: HTMLElement | Document = document) {
    burger?.removeEventListener('click', burgerChange);
    closeMenu();
}

const _module = { init, destroy };

export default _module;
