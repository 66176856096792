import './CookiesAgreement/CookiesAgreement';
import './Collapse/Collapse';
import './LitPopupElement/LitPopupElement';
import { YandexMap } from './YandexMap/YandexMap';
//import { WebShare } from './WebShare/WebShare';

export function initCustomElements() {
    customElements.define('app-yandex-map', YandexMap);
}

// export function destroyCustomElements() {
//     const maps = document.querySelectorAll<YandexMap>('app-yandex-map');
//     maps.forEach(map => map.disconnectedCallback());
// }
