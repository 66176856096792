import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
let firstSection: HTMLElement | null;
let stickyTl: gsap.core.Timeline | null;

const beforeResize = {
    width: window.innerWidth,
};

function onresize() {
    if (Math.abs(beforeResize.width - window.innerWidth) > 10) {
        beforeResize.width = window.innerWidth;

        if (stickyTl) {
            stickyTl.invalidate();
            stickyTl.restart();
        }
    }
}

function stickyFiltersInit() {
    if (firstSection) {
        stickyTl = gsap.timeline({
            scrollTrigger: {
                trigger: firstSection,
                pin: true,
                pinSpacing: false,
            },
        });
    }
}

function init(container: HTMLElement | Document = document) {
    firstSection = container.querySelector<HTMLElement>('.js-first-bg-section');
    const breadcrumbs = container.querySelector<HTMLElement>('.js-breadcrumbs');

    if (firstSection) {
        if (breadcrumbs) breadcrumbs.style.position = 'fixed';
        stickyFiltersInit();
    } else {
        if (breadcrumbs) breadcrumbs.style.position = 'relative';
    }

    window.addEventListener('resize', onresize);
}

function destroy(container: HTMLElement | Document = document) {
    stickyTl?.kill();
    stickyTl = null;

    window.removeEventListener('resize', onresize);
}

const _module = { init, destroy };

export default _module;
