import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import barba from '@barba/core';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import inputMasks from './inits/masks';
import preloader from './components/preloader';
import seasonSwither from './inits/season-switcher';
import { Cursor } from './inits/cursor-hover';
import BaseTransition from './transitions/base';
import { initCustomElements } from './custom-elements';
import cookie from './inits/cookie-season';
import initSosButton from './inits/sos-button';
import initSearchInput from './inits/search-input';
import initSearchForm from './inits/search-form';
import reveal from './animations/reveal';
import getDate from './inits/getDate';
import initInfoBanner from './inits/info-banner';

import ajaxForm from './inits/ajax-form';
import generatePageCurtain from './inits/generate-page-curtain';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    cookie.init();
    seasonSwither.init();
    Cursor().init();
    initCustomElements();
    ajaxForm.init();
    generatePageCurtain();

    document.addEventListener('preloader.complete', () => {
        window.scrollTo({ top: 0, behavior: 'auto' });

        gsap.config({ nullTargetWarn: false });
        gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin, CustomEase, DrawSVGPlugin);

        initBaseView();
        calculateScrollbarWidth();
        initSearchInput();
        initSearchForm();
        const footer = document.querySelector<HTMLElement>('.js-footer');

        if (footer) {
            reveal.init(footer);
        }

        const burgerPopup = document.querySelector<HTMLElement>('.js-burger-popup');

        if (burgerPopup) {
            getDate.init(burgerPopup);
        }

        const requestPopupEl = document.querySelector('.form-popup');
        if (requestPopupEl) {
            inputMasks.init(requestPopupEl);
        }
        initSosButton();
        // Code here
        barba.init({
            transitions: [BaseTransition],
            views: [
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page" */ './views/index').then((m) => m.default),
                ),
                lazyView('error-page', () =>
                    import(/* webpackChunkName: "error-page" */ './views/error').then((m) => m.default),
                ),
                lazyView('map-page', () =>
                    import(/* webpackChunkName: "map-page" */ './views/map').then((m) => m.default),
                ),
                lazyView('component-page', () =>
                    import(/* webpackChunkName: "component-page" */ './views/component').then((m) => m.default),
                ),

                lazyView('partners-page', () =>
                    import(/* webpackChunkName: "partners-page" */ './views/partners').then((m) => m.default),
                ),
                lazyView('detail-page', () =>
                    import(/* webpackChunkName: "detail-page" */ './views/detail').then((m) => m.default),
                ),
                lazyView('event-page', () =>
                    import(/* webpackChunkName: "event-page" */ './views/event-page').then((m) => m.default),
                ),

                lazyView('discount-page', () =>
                    import(/* webpackChunkName: "discount-page" */ './views/discount').then((m) => m.default),
                ),

                lazyView('calendar-page', () =>
                    import(/* webpackChunkName: "calendar-page" */ './views/calendar').then((m) => m.default),
                ),

                lazyView('webcamers-page', () =>
                    import(/* webpackChunkName: "webcamers-page" */ './views/webcamers').then((m) => m.default),
                ),

                lazyView('objects-page', () =>
                    import(/* webpackChunkName: "objects-page" */ './views/objects').then((m) => m.default),
                ),

                lazyView('infrastructure-page', () =>
                    import(/* webpackChunkName: "infrastructure-page" */ './views/infrastructure').then(
                        (m) => m.default,
                    ),
                ),

                lazyView('news-page', () =>
                    import(/* webpackChunkName: "news-page" */ './views/news').then((m) => m.default),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            timeout: 5000,
            debug: NODE_ENV === 'development',
        });

        initInfoBanner();
    });

    preloader.init();
});

// Always at the end
//module.hot?.accept();
