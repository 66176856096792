export default function initSearchInput() {
    const searchInput = document.querySelector<HTMLInputElement>('.js-search-input');
    const clearInput = document.querySelector('.js-clear-input');
    const searchInputWrapper = document.querySelector('.js-search-input-wrapper');
    const searchButton = document.querySelector<HTMLButtonElement>('.js-search-submit');
    const changeEvent = new Event('change');

    const onFocus = () => {
        if (searchInputWrapper) {
            searchInputWrapper.classList.add('is-focused');
        }
    }

    const onBlur = () => {
        if (searchInputWrapper) {
            searchInputWrapper.classList.remove('is-focused');
        }
    }

    const onClick = () => {
        if (searchInput) {
            searchInput.value = '';
            searchInput.dispatchEvent(changeEvent);
        }
    }

    const onEmpty = () => {
        if (clearInput) {
            clearInput.classList.remove('is-show');
        }
        if (searchButton) {
            searchButton.setAttribute('disabled', 'disabled');
        }
    }

    const onNotEmpty = () => {
        if (clearInput) {
            clearInput.classList.add('is-show');
        }
        if (searchButton) {
            searchButton.removeAttribute('disabled');
        }
    }

    const onInput = () => {
        if (searchInput) {
            searchInput.value === '' ? onEmpty() : onNotEmpty();
        }
    }

    if (searchInput) {
        searchInput.addEventListener('focus', onFocus);
        searchInput.addEventListener('blur', onBlur);
        searchInput.addEventListener('input', onInput);
        searchInput.addEventListener('change', onInput);
    }

    if (clearInput) {
        clearInput.addEventListener('click', onClick);
    }
}