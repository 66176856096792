import { LitElement, css, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import * as Cookies from 'js-cookie';

import _styles from './CookiesAgreement.ce.scss';

export interface CookiesAgreement {
    revealTimeout: number;
    _isVisible: boolean;
    _timer: NodeJS.Timeout;
}

/**
 * @attr {Number} reveal-timeout - Через сколько секунд сообщение покажется пользователю
 *
 * @slot - Контент в формате HTML
 */
export class CookiesAgreement extends LitElement {
    constructor() {
        super();
        this._acceptCookiesUsage = this._acceptCookiesUsage.bind(this);
        this._isVisible = false;
    }

    static get properties() {
        return {
            _isVisible: {
                type: Boolean,
                attribute: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('preloader.hidden', () => {
            this._isVisible = true;
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._timer);
    }

    close() {
        this._isVisible = false;
    }

    protected _acceptCookiesUsage() {
        Cookies.set('COOKIES_USAGE_ACCEPTED', 'true', {
            expires: 365, // days
        });
        this.close();
    }

    render() {
        return html`
            <div class="${classMap({ banner: true, 'banner--visible': this._isVisible })}">
                <div class="banner__top">
                    <div class="banner__icon">
                        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M45.1385 19.5583C45.3115 20.6812 45.401 21.8291 45.401 23C45.401 35.3604 35.3656 45.3958 23.0052 45.3958C10.6448 45.3958 0.609375 35.3604 0.609375 23C0.609375 10.6395 10.6448 0.604126 23.0052 0.604126C23.9615 0.604126 24.9031 0.664543 25.8281 0.781209C26.326 0.843709 26.7615 1.13954 27.0031 1.57913C27.2427 2.01663 27.2594 2.54579 27.0469 2.99788C26.7927 3.53538 26.651 4.13746 26.651 4.77079C26.651 6.12496 27.299 7.32913 28.301 8.08954C28.7844 8.45829 29.0115 9.07496 28.8802 9.67079C28.7844 10.1062 28.7344 10.5562 28.7344 11.0208C28.7344 14.4708 31.5344 17.2708 34.9844 17.2708C35.1906 17.2708 35.3927 17.2604 35.5948 17.2416C36.1156 17.1916 36.626 17.4041 36.9573 17.8104C37.7219 18.752 38.8865 19.3541 40.1927 19.3541C41.1177 19.3541 41.974 19.052 42.6656 18.5416C43.1052 18.2166 43.6823 18.1437 44.1885 18.352C44.6948 18.5604 45.0531 19.0187 45.1385 19.5583ZM42.2594 22.1812C41.6031 22.375 40.9094 22.4791 40.1927 22.4791C38.2073 22.4791 36.4052 21.6833 35.0906 20.3958C35.0552 20.3958 35.0198 20.3958 34.9844 20.3958C29.8094 20.3958 25.6094 16.1958 25.6094 11.0208C25.6094 10.6541 25.6302 10.2916 25.6719 9.93538C24.3469 8.61454 23.526 6.78746 23.526 4.77079C23.526 4.42079 23.551 4.07496 23.599 3.73746C23.401 3.73121 23.2031 3.72913 23.0052 3.72913C12.3698 3.72913 3.73438 12.3645 3.73438 23C3.73438 33.6354 12.3698 42.2708 23.0052 42.2708C33.6406 42.2708 42.276 33.6354 42.276 23C42.276 22.725 42.2698 22.452 42.2594 22.1812Z"
                                fill="#4FABE9"
                                stroke="#4FABE9"
                            />
                            <path
                                d="M32.901 31.8541C34.3393 31.8541 35.5052 30.6882 35.5052 29.25C35.5052 27.8117 34.3393 26.6458 32.901 26.6458C31.4628 26.6458 30.2969 27.8117 30.2969 29.25C30.2969 30.6882 31.4628 31.8541 32.901 31.8541Z"
                                fill="#4FABE9"
                                stroke="#4FABE9"
                            />
                            <path
                                d="M17.276 37.0625C18.7143 37.0625 19.8802 35.8965 19.8802 34.4583C19.8802 33.0201 18.7143 31.8541 17.276 31.8541C15.8378 31.8541 14.6719 33.0201 14.6719 34.4583C14.6719 35.8965 15.8378 37.0625 17.276 37.0625Z"
                                fill="#4FABE9"
                                stroke="#4FABE9"
                            />
                            <path
                                d="M21.4401 20.9166C22.8783 20.9166 24.0443 19.7507 24.0443 18.3125C24.0443 16.8742 22.8783 15.7083 21.4401 15.7083C20.0019 15.7083 18.8359 16.8742 18.8359 18.3125C18.8359 19.7507 20.0019 20.9166 21.4401 20.9166Z"
                                fill="#4FABE9"
                                stroke="#4FABE9"
                            />
                        </svg>
                    </div>
                    <button
                        class="banner__close"
                        @click="${this._acceptCookiesUsage}"
                        aria-label="Принять и закрыть"
                        title="Принять и закрыть"
                    >
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.46707 25.7771C8.86784 26.1778 9.51761 26.1778 9.91838 25.7771L16.6416 19.0539L23.4107 25.823C23.8115 26.2238 24.4612 26.2238 24.862 25.823C25.2628 25.4222 25.2628 24.7725 24.862 24.3717L18.0929 17.6026L25.157 10.5384C25.5578 10.1376 25.5578 9.48787 25.157 9.0871C24.7563 8.68634 24.1065 8.68633 23.7057 9.0871L16.6416 16.1513L9.62332 9.13303C9.22256 8.73227 8.57279 8.73227 8.17202 9.13303C7.77126 9.5338 7.77126 10.1836 8.17202 10.5843L15.1903 17.6026L8.46707 24.3258C8.06631 24.7265 8.06631 25.3763 8.46707 25.7771Z"
                                fill="#2F2F2F"
                            />
                        </svg>
                    </button>
                </div>
                <div class="banner__title">Cookies</div>
                <div class="banner__text">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-cookies-agreement': CookiesAgreement;
    }
}

if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
    customElements.define('app-cookies-agreement', CookiesAgreement);
}
