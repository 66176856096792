import getCookie from '../utils/get-cookie';

let seasonSwitcher: HTMLElement | null;
let seasonSwitcherBox: HTMLElement | null;
let season: string | undefined;
let timer: NodeJS.Timeout;

function seasonChange() {
    clearTimeout(timer);
    seasonSwitcherBox?.classList.toggle('summer');
    season = getCookie('season');

    if (season == 'summer') {
        document.cookie = `season=winter; max-age=${86400 * 30}`;
    } else {
        document.cookie = `season=summer; max-age=${86400 * 30}`;
    }

    timer = setTimeout(() => {
        location.reload();
    }, 400);
}

function init(container: HTMLElement | Document = document) {
    seasonSwitcher = container.querySelector<HTMLElement>('.season-switcher');
    seasonSwitcherBox = container.querySelector<HTMLElement>('.season-switcher__box');
    seasonSwitcher?.addEventListener('click', seasonChange);
}

const _module = { init };

export default _module;
