import axios from 'axios';
import filtersDrag from './filters-drag';
import { fetchContentList, fetchContentListFilter } from '../dynamic-modules';
import { gsap } from 'gsap';

const animateSearchResults = (container: HTMLElement, content: string) => {
    gsap.set(container, { opacity: 0 });
    container.innerHTML = content;
    const searchCount = container.querySelector('.js-search-count');
    const filterItems = Array.from(container.querySelectorAll('.js-search-filter-item'));
    const searchItems = Array.from(container.querySelectorAll('.js-search-item'));
    const pagination = container.querySelector('.js-search-pagination');
    gsap.timeline()
        .to(container, { opacity: 1 })
        .from(searchCount, { y: 10, opacity: 0 }, 0)
        .from(filterItems, { x: 10, opacity: 0, stagger: 0.05 }, 0.1)
        .from(searchItems, { y: 20, opacity: 0, stagger: 0.03 }, 0.2)
        .from(pagination, { y: 10, opacity: 0 }, 0.3);
};

export default function initSearchForm() {
    const searchForm = document.querySelector<HTMLFormElement>('.js-search-form');
    const searchInput = document.querySelector<HTMLInputElement>('.js-search-input');
    const searchInputWrapper = document.querySelector('.js-search-input-wrapper');
    const searchInputMessage = searchInputWrapper?.querySelector('.app-message');
    const action = searchForm ? searchForm.getAttribute('action') || '' : '';
    const method = searchForm ? searchForm.getAttribute('method') || 'get' : 'get';
    const searchContentInner = document.querySelector<HTMLElement>('.js-search-content-inner');

    const onNotValid = () => {
        if (searchInputMessage) {
            searchInputMessage.innerHTML = 'Введите значение';
        }
    };

    const clearSearchInputMessage = () => {
        if (searchInputMessage) {
            searchInputMessage.innerHTML = '';
        }
    };

    const onSubmit = (event: Event) => {
        event.preventDefault();

        if (searchForm) {
            if (searchInput && searchInput.value.trim() === '') {
                onNotValid();
                return;
            } else {
                clearSearchInputMessage();
            }

            const formData = new FormData(searchForm);
            const search = new URLSearchParams(formData);
            const queryString = search.toString();
            const searchInputValue = searchInput ? searchInput.value || '' : '';

            if (searchInputValue) {
                axios({
                    method,
                    url: `${action}?${queryString}`,
                    data: formData,
                }).then((response) => {
                    if (response.data.success) {
                        if (searchContentInner && response.data.data.contentHtml) {
                            animateSearchResults(searchContentInner, response.data.data.contentHtml);
                            filtersDrag.init(searchContentInner);

                            const newsEl = searchContentInner.querySelector<HTMLElement>('.js-news');
                            const filterContainer = searchContentInner.querySelector<HTMLElement>('.js-news-filter');

                            if (newsEl) {
                                Promise.all([fetchContentList(), fetchContentListFilter()]).then(
                                    ([ContentList, ContentListFilter]) => {
                                        if (newsEl) {
                                            const catalog = new ContentList(newsEl, {
                                                modules: [ContentListFilter],
                                                pushState: false,
                                                cacheRequests: false,
                                                scrollTo: {
                                                    el: filterContainer || newsEl,
                                                },
                                                onFetchSuccess: (instance, response) => {
                                                    if (response.data) {
                                                        if (response.data.contentHtml && instance.contentEl) {
                                                            animateSearchResults(
                                                                instance.contentEl,
                                                                response.data.contentHtml,
                                                            );
                                                        }
                                                    }
                                                },
                                                filter: {
                                                    el: filterContainer,
                                                    filterOnInputChange: true,
                                                },
                                            });
                                        }
                                    },
                                );
                            }
                        }
                    }
                });
            }
        }
    };

    if (searchForm) {
        searchForm.addEventListener('submit', onSubmit);
    }

    if (searchInput) {
        searchInput.addEventListener('input', clearSearchInputMessage);
        searchInput.addEventListener('change', clearSearchInputMessage);
    }
}
