import SlimSelect from 'slim-select';
const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    const selectList = container.querySelectorAll<HTMLElement>('.js-price-sort');

    selectList.forEach(select => {
        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select: select,
                showSearch: false,

               // placeholder: select.dataset.namePlaceholder,
                showContent: 'down',
                hideSelectedOption: true,
                valuesUseText: false, // Use text instead of innerHTML for selected values - default false
                data: [
                  {innerHTML: ' <span class="infactructure-sorting__heading button-text"><span class="infactructure-sorting__icon"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.7162 14.1664H8.18671V12.5946H13.6749C14.1181 12.5946 14.4823 12.5242 14.7674 12.3834C15.056 12.2425 15.269 12.0467 15.4064 11.7959C15.5473 11.5451 15.616 11.2514 15.6126 10.9147C15.616 10.5849 15.5473 10.2894 15.4064 10.0283C15.269 9.76378 15.0595 9.55593 14.7777 9.40477C14.4995 9.25017 14.1456 9.17287 13.7162 9.17287H11.6446V18.1396H9.73784V7.58566H13.7162C14.5338 7.58566 15.2261 7.73339 15.7929 8.02884C16.3598 8.32086 16.7892 8.71767 17.0813 9.21925C17.3767 9.7174 17.5244 10.2774 17.5244 10.8992C17.5244 11.5451 17.375 12.1137 17.0761 12.605C16.7772 13.0928 16.3443 13.4759 15.7775 13.7541C15.2106 14.029 14.5235 14.1664 13.7162 14.1664ZM13.9584 14.9497V16.5214H8.18671V14.9497H13.9584Z" fill="#4FABE9"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.8838C19.0109 23.8838 23.8837 19.0109 23.8837 13C23.8837 6.98912 19.0109 2.11631 13 2.11631C6.98909 2.11631 2.11628 6.98912 2.11628 13C2.11628 19.0109 6.98909 23.8838 13 23.8838ZM13 26C20.1797 26 26 20.1797 26 13C26 5.82033 20.1797 3.05176e-05 13 3.05176e-05C5.8203 3.05176e-05 0 5.82033 0 13C0 20.1797 5.8203 26 13 26Z" fill="#4FABE9"></path></svg> </span>Цена</span><span class="infactructure-sorting__bar"></span><span class="infactructure-sorting__increase button-text">Без сортировки</span>', text: '', value: 'noSort'},
                  {innerHTML: ' <span class="infactructure-sorting__heading button-text"><span class="infactructure-sorting__icon"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.7162 14.1664H8.18671V12.5946H13.6749C14.1181 12.5946 14.4823 12.5242 14.7674 12.3834C15.056 12.2425 15.269 12.0467 15.4064 11.7959C15.5473 11.5451 15.616 11.2514 15.6126 10.9147C15.616 10.5849 15.5473 10.2894 15.4064 10.0283C15.269 9.76378 15.0595 9.55593 14.7777 9.40477C14.4995 9.25017 14.1456 9.17287 13.7162 9.17287H11.6446V18.1396H9.73784V7.58566H13.7162C14.5338 7.58566 15.2261 7.73339 15.7929 8.02884C16.3598 8.32086 16.7892 8.71767 17.0813 9.21925C17.3767 9.7174 17.5244 10.2774 17.5244 10.8992C17.5244 11.5451 17.375 12.1137 17.0761 12.605C16.7772 13.0928 16.3443 13.4759 15.7775 13.7541C15.2106 14.029 14.5235 14.1664 13.7162 14.1664ZM13.9584 14.9497V16.5214H8.18671V14.9497H13.9584Z" fill="#4FABE9"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.8838C19.0109 23.8838 23.8837 19.0109 23.8837 13C23.8837 6.98912 19.0109 2.11631 13 2.11631C6.98909 2.11631 2.11628 6.98912 2.11628 13C2.11628 19.0109 6.98909 23.8838 13 23.8838ZM13 26C20.1797 26 26 20.1797 26 13C26 5.82033 20.1797 3.05176e-05 13 3.05176e-05C5.8203 3.05176e-05 0 5.82033 0 13C0 20.1797 5.8203 26 13 26Z" fill="#4FABE9"></path></svg> </span>Цена</span><span class="infactructure-sorting__bar"></span><span class="infactructure-sorting__increase button-text">По убыванию</span>', text: '', value: 'desc'},
                  {innerHTML: ' <span class="infactructure-sorting__heading button-text"><span class="infactructure-sorting__icon"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.7162 14.1664H8.18671V12.5946H13.6749C14.1181 12.5946 14.4823 12.5242 14.7674 12.3834C15.056 12.2425 15.269 12.0467 15.4064 11.7959C15.5473 11.5451 15.616 11.2514 15.6126 10.9147C15.616 10.5849 15.5473 10.2894 15.4064 10.0283C15.269 9.76378 15.0595 9.55593 14.7777 9.40477C14.4995 9.25017 14.1456 9.17287 13.7162 9.17287H11.6446V18.1396H9.73784V7.58566H13.7162C14.5338 7.58566 15.2261 7.73339 15.7929 8.02884C16.3598 8.32086 16.7892 8.71767 17.0813 9.21925C17.3767 9.7174 17.5244 10.2774 17.5244 10.8992C17.5244 11.5451 17.375 12.1137 17.0761 12.605C16.7772 13.0928 16.3443 13.4759 15.7775 13.7541C15.2106 14.029 14.5235 14.1664 13.7162 14.1664ZM13.9584 14.9497V16.5214H8.18671V14.9497H13.9584Z" fill="#4FABE9"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.8838C19.0109 23.8838 23.8837 19.0109 23.8837 13C23.8837 6.98912 19.0109 2.11631 13 2.11631C6.98909 2.11631 2.11628 6.98912 2.11628 13C2.11628 19.0109 6.98909 23.8838 13 23.8838ZM13 26C20.1797 26 26 20.1797 26 13C26 5.82033 20.1797 3.05176e-05 13 3.05176e-05C5.8203 3.05176e-05 0 5.82033 0 13C0 20.1797 5.8203 26 13 26Z" fill="#4FABE9"></path></svg> </span>Цена</span><span class="infactructure-sorting__bar"></span><span class="infactructure-sorting__increase button-text">По возрастанию</span>', text: '', value: 'asc'},
        
                ],
                beforeOpen: function() {
                },
                onChange: function(info) {
                }
            });
            map.set(select, newselect);

            select.classList.add('select--initialization');

            select.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });
        }
    })
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-price-sort')).forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
